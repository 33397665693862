<template>
  <div class="m-2">
    <!-- Table Top -->
    <b-row>
      <!-- Per Page -->
      <b-col
        md="4"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label v-if="showEntries">{{ $t('table.Show') }}</label>
        <v-select
          v-if="showEntries"
          v-model="internalPerPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label v-if="showEntries">{{ $t('table.entries') }}</label>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center justify-content-end">
          <b-dropdown
            right
            variant="primary"
            class="mr-1"
            :disabled="total==0"
          >
            <template #button-content>
              <feather-icon
                icon="FileTextIcon"
                class="mr-50"
                size="20"
              />
              {{ $t('purchases.export') }}
            </template>
            <b-dropdown-item
              v-b-tooltip.hover.top="total > 150000 ? $t('dems') : ''"
              :disabled="total > 150000"
              @click="exportTable('CSV')"
            >
              CSV
            </b-dropdown-item>
            <b-dropdown-item @click="exportTable('XLS')">
              XLS
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
      <b-col md="4">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="internalSearchQuery"
            class="d-inline-block mr-1"
            :placeholder="$t('Search')"
          />
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="datesExport"
      ref="datesExport"
      hide-footer
      size="lg"
      :title="$t('purchases.export')+' '+typeExport"
    >
      <b-overlay
        :show="overlay"
        variant="transparent"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <b-row>
          <b-col>
            <b-tabs
              v-model="exportationTabs"
              pills
              card
              vertical
            >
              <b-tab
                :title="$t('filtDate')"
                active
              >
                <flat-pickr
                  v-model="timeFilter"
                  class="form-control"
                  :config="{
                    mode: 'range',
                    enableSeconds: true,
                    time_24hr: true,
                    dateFormat: 'Y-m-d',
                    maxDate: new Date()
                  }"
                />
                <h6 class="mt-2 ml-1">
                  {{ $t('elementosToExport', { n: format(totalByDate) }) }}
                </h6>
              </b-tab>
              <b-tab :title="$t('Todos')">
                <b-button
                  disabled
                  variant="outline-warning"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    class="mr-50 "
                    size="20"
                  />
                  <span style="line-height: 1.2;">
                    {{ $t('sureExport') }}
                  </span>
                </b-button>
                <h6 class="mt-2 ml-1">
                  {{ $t('elementosToExport', { n: format(total) }) }}
                </h6>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-1">
          <b-col style="text-align:end">
            <b-button
              :disabled="exportationTabs==0 && totalByDate==0"
              class="mr-2"
              variant="success"
              @click="exportData()"
            >
              {{ $t('purchases.export') }}
            </b-button>
          </b-col>
        </b-row>

        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click.stop.prevent="$refs.datesExport.hide()"
        >
          {{ $t("dataGeneric.close") }}
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BOverlay,
  BButton,
  BTabs,
  BTab,
  BFormInput, BCol, BRow,
  VBTooltip,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { getUserData } from '@/auth/utils'
import axios from '@axios'

export default {
  components: {
    BButton,
    BFormInput,
    BOverlay,
    flatPickr,
    BCol,
    BDropdown,
    BModal,
    BTabs,
    BTab,
    BDropdownItem,
    BRow,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
      required: false,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    callMethod: {
      type: String,
      required: true,
    },
    showEntries: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:perPage', 'update:searchQuery'],
  data() {
    return {
      internalPerPage: 10,
      exportationTabs: 0,
      internalSearchQuery: '',
      timeFilter: this.getDefaultTimeFilter(),
      perPageOptions: [10, 25, 50, 100],
      typeExport: null,
      filterDates: [],
      totalByDate: 0,
      overlay: false,
      startDate: null,
      endDate: null,
    }
  },
  watch: {
    internalPerPage(newValue) {
      this.$emit('update:perPage', newValue)
    },
    internalSearchQuery(newValue) {
      this.$emit('update:searchQuery', newValue)
    },
    perPage(newValue) {
      this.internalPerPage = newValue
    },
    searchQuery(newValue) {
      this.internalSearchQuery = newValue
    },
    timeFilter() {
      try {
        const separacion = this.timeFilter.split('to')
        const i = new Date(separacion[0])
        i.setHours(0, 0, 0)
        const f = new Date(separacion[1])
        f.setHours(23, 59, 59)
        const guardar = [i.getTime(), f.getTime()]
        if (separacion.length > 1) this.filterDates = guardar
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    filterDates() {
      this.getTotalCountByDates()
    },
  },

  methods: {
    formatTimestamp(timestamp) {
      const date = new Date(timestamp)

      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // Sumar 1 porque enero es 0
      const day = date.getDate().toString().padStart(2, '0')
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      const seconds = date.getSeconds().toString().padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`

      return formattedDate
    },
    exportData() {
      if (this.exportationTabs === 0) {
        this.$emit('export', [this.typeExport, this.startDate, this.endDate])
      } else {
        this.$emit('export', this.typeExport)
      }
      this.timeFilter = this.getDefaultTimeFilter()
      this.$refs.datesExport.hide()
    },
    getTotalCountByDates() {
      this.overlay = true

      if (Array.isArray(this.filterDates)) {
        this.startDate = this.formatTimestamp(this.filterDates[0])
        this.endDate = this.formatTimestamp(this.filterDates[1])
      }
      axios
        .post('', {
          query: `
        {
        ${this.callMethod}(client:"${getUserData().profile.client.id}",startDate:"${this.startDate}",endDate:"${this.endDate}"){           
            totalCount          
          }
        }
      `,
        }).then(result => {
          const { totalCount } = result.data.data[`${this.callMethod}`]
          this.totalByDate = totalCount
          this.overlay = false
        }).catch(err => {
          console.log(err)
        })
    },
    format(num) {
      const reg = /\d{1,3}(?=(\d{3})+$)/g
      const res = (`${num}`).replace(reg, '$&.')
      return res === 'null' ? 0 : res
    },
    getDefaultTimeFilter() {
      const startDate = new Date()
      startDate.setHours(0, 0, 0)
      const endDate = new Date()
      endDate.setHours(23, 59, 59)
      // Formatear las fechas a 'Y-m-d'
      const formatDate = date => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`

      return [formatDate(startDate), formatDate(endDate)]
    },
    exportTable(type) {
      this.typeExport = type
      const fechasArray = []
      const dats = Array.isArray(this.timeFilter) ? this.timeFilter : this.filterDates
      dats.forEach(dates => {
        const date = new Date(dates)
        fechasArray.push(date.getTime())
      })
      this.filterDates = fechasArray
      this.$refs.datesExport.show()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
